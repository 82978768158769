import sortByName from '@/modules/cms/helpers/sortByName';
import uppercaseFirstLetter from '@/modules/cms/helpers/uppercaseFirstLetter';

export const loadIngredients = (state) => {
  state.ingredientList.isLoading = true;
};

export const updateIngredients = (state, { status, ingredients }) => {
  if (status && Array.isArray(ingredients)) {
    const ingredients_ = ingredients.filter(
      (ingredient) =>
        (ingredient.nombre = uppercaseFirstLetter(ingredient.nombre))
    );

    const ingredientsArray = ingredients_.sort(sortByName);
    state.ingredientList.data = ingredientsArray;
  } else {
    state.ingredientList.messageError =
      'Hubo un error al cargar los ingredientes 🙁';
  }

  setTimeout(() => {
    state.ingredientList.isLoading = false;
  }, 1600);
};
