const skeletons = [1, 2, 3, 4, 5, 6, 7, 8];

export default () => ({
  ingredientSkeletons: skeletons,
  ingredientList: {
    data: [],
    messageError: '',
    isLoading: false,
  },
  ingredientSelected: {},
  ingredientMetaCategories: [],
});
