const baseUrl = process.env.VUE_APP_API_PRO;

const fetchNotToken = (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}${endpoint}`;

  if (method === 'GET') {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchWithToken = (endpoint, data, method = 'GET', formData = false) => {
  const url = `${baseUrl}${endpoint}`;
  const token = localStorage.getItem('token') || '';

  if (method === 'GET') {
    const headers = {
      Authorization: 'Bearer ' + token,
    };
    return fetch(url, {
      method,
      headers,
    });
  } else {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer  ' + token,
    };

    const requestOptions = {
      method,
      headers,
      body: JSON.stringify(data),
    };

    if (Object.keys(data).length === 0) delete requestOptions.body;

    if (formData) {
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);

      delete requestOptions.headers;
      delete requestOptions.body;

      requestOptions.headers = myHeaders;
      requestOptions.body = data;
    }

    return fetch(url, requestOptions);
  }
};

export { fetchNotToken, fetchWithToken };
