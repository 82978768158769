export default {
  name: 'cms',
  component: () =>
    import(
      /* webpackChunkName: "Cms Layout" */ '@/modules/cms/layouts/CmsLayout.vue'
    ),
  children: [
    {
      path: '',
      name: 'recipes',
      component: () =>
        import(
          /* webpackChunkName: "Recipes View" */ '@/modules/cms/views/Recipes.vue'
        ),
    },

    {
      path: 'ingredientes',
      name: 'ingredients',
      component: () =>
        import(
          /* webpackChunkName: "Ingredients View" */ '@/modules/cms/views/Ingredients.vue'
        ),
    },
    {
      path: 'productos',
      name: 'products',
      component: () =>
        import(
          /* webpackChunkName: "Products View" */ '@/modules/cms/views/Products.vue'
        ),
    },
    {
      path: 'familia',
      name: 'family',
      component: () =>
        import(
          /* webpackChunkName: "Family View" */ '@/modules/cms/views/Family.vue'
        ),
    },
    {
      path: ':pathMatch(.*)*',
      redirect: {
        name: 'recipes',
      },
    },
  ],
};
