/* export const myMutation = (state)=> {} */
export const loginUser = (state, { token }) => {
  if (token) {
    localStorage.setItem('token', token);
    state.token = token;
    state.status = 'authenticated';
  }
};

export const logout = (state) => {
  state.token = null;
  state.status = 'not-authenticated';

  localStorage.removeItem('token');
};
