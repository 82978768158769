import sortByName from '@/modules/cms/helpers/sortByName';
import uppercaseFirstLetter from '@/modules/cms/helpers/uppercaseFirstLetter';

export const loadRecipes = (state) => {
  state.recipeList.isLoading = true;
  state.recipeSelected.isLoading = true;
};

export const updateRecipes = (state, { status, recipes }) => {
  if (status && Array.isArray(recipes)) {
    const recipeArray = recipes.sort(sortByName);
    state.recipeList.data = recipeArray;
  } else {
    state.recipeList.messageError = 'Error al cargar las recetas 🙁';
  }

  setTimeout(() => {
    state.recipeList.isLoading = false;
    state.recipeSelected.isLoading = false;
  }, 1600);
};

export const loadSelectedRecipe = (state, isLoading) => {
  state.recipeSelected.isLoading = isLoading;
};

export const clearSelectedRecipe = (state) => {
  state.recipeSelected = {
    messageError: '',
    isLoading: false,
    data: {
      id: '',
      nombre: '',
      detalles: '',
      imagen: '',
      imagen2: '',
      imagen3: '',
      autor: '',
      porciones: '',
      tiempo: '',
      revisado: '',
    },
    id: '',
    preparationSteps: [],
    ingredientList: [],
    ingredientEdit: {
      idIngredient: null,
      idRecipeIngredient: null,
      unitList: [],
    },
    categories: {
      metaCategories: [],
      data: {},
    },
  };
};

export const showRecipeMain = (
  state,
  { status, recipe, preparationSteps, recipeIngredients, recipeCategories }
) => {
  if (status) {
    state.recipeSelected.data = recipe;
    state.recipeSelected.id = recipe.id;
    state.recipeSelected.preparationSteps = preparationSteps;

    /* Ingredients */
    const ingredients_ = Object.values(recipeIngredients).filter(
      (ingredient) =>
        (ingredient.nombre = uppercaseFirstLetter(ingredient.nombre))
    );
    const ingredientsArray = ingredients_.sort(sortByName);
    state.recipeSelected.ingredientList = ingredientsArray;
    /* ------------- */

    /* Categories */
    const categoriesInitial = Object.values(recipeCategories);
    const categories = {};
    let metaCategories = [];

    categoriesInitial.map((category) => {
      const metaCategory = uppercaseFirstLetter(category.metaCategoria);
      if (metaCategory === 'Final') {
        metaCategories = [...metaCategories, metaCategory];
        if (!categories[metaCategory]) categories[metaCategory] = [];
        categories[metaCategory] = [...categories[metaCategory], category];
      }
    });

    state.recipeSelected.categories.metaCategories = [
      ...new Set(metaCategories),
    ];

    state.recipeSelected.categories.data = categories;
    /* ---------------------------- */
  } else {
    state.recipeSelected.messageError =
      'Error al cargar la receta seleccionada 🙁';
  }
};

export const updateRecipeIngredients = (state, ingredients) => {
  state.recipeSelected.ingredientList = ingredients;
};

export const editRecipeIngredient = (state, ingredient) => {
  state.recipeSelected.ingredientEdit = {
    ...state.recipeSelected.ingredientEdit,
    ...ingredient,
  };
};
