export default {
  name: 'auth',
  component: () =>
    import(
      /* webpackChunkName: "Auth Layout" */ '@/modules/auth/layouts/AuthLayout.vue'
    ),
  children: [
    {
      path: '',
      name: 'login',
      component: () =>
        import(
          /* webpackChunkName: "Login View" */ '@/modules/auth/views/Login.vue'
        ),
    },
    {
      path: '/registro',
      name: 'register',
      component: () =>
        import(
          /* webpackChunkName: "Register View" */ '@/modules/auth/views/Register.vue'
        ),
    },
    {
      path: ':pathMatch(.*)*',
      redirect: {
        name: 'login',
      },
    },
  ],
};
