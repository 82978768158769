export const loadProducts = (state) => {
  state.productList.isLoading = true;
};

export const updateProducts = (state, { status, productos }) => {
  if (status) {
    state.productList.data = productos;
    state.productList.error = false;
  } else {
    state.productList.error = true;
    state.productList.messageError = 'Hubo un error al cargar los productos 🙁';
  }

  setTimeout(() => {
    state.productList.isLoading = false;
  }, 1600);
};
