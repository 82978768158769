import { createRouter, createWebHistory } from 'vue-router';

import authRouter from '@/modules/auth/router';
import cmsRouter from '@/modules/cms/router';
import isAuthenticatedGuard from '@/modules/auth/router/auth-guard';

const routes = [
  {
    path: '/',
    ...authRouter,
  },
  {
    path: '/cms',
    beforeEnter: [isAuthenticatedGuard],
    ...cmsRouter,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
