import { fetchWithToken } from '@/helpers/fetch';

export const updateRecipes = async ({ commit }) => {
  commit('loadRecipes');
  try {
    const recipesPromise = await fetchWithToken('/recetas');
    const recipes = await recipesPromise.json();
    commit('updateRecipes', {
      recipes,
      status: true,
    });
  } catch (error) {
    commit('updateRecipes', { status: false });
  }
};

export const showSelectedRecipe = async (_, recipeID) => {
  try {
    const recipePromise = await fetchWithToken(`/recetas/${recipeID}`);
    const selectedRecipe = await recipePromise.json();

    return {
      status: true,
      selectedRecipe,
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      msg: 'La receta no se pudo cargar 😥',
    };
  }
};

export const deleteRecipe = async ({ commit }, recipeID) => {
  try {
    const recipePromise = await fetchWithToken(
      `/recetas/${recipeID}`,
      {},
      'DELETE'
    );
    const { status, message } = await recipePromise.json();
    const success = status === 'success';
    if (success) {
      commit('clearSelectedRecipe');
      return { status: success, msg: message };
    }

    return { status: success, msg: 'Hubo un error al eliminar la receta 😥' };
  } catch (error) {
    console.error(error);
    return { status: false, msg: 'Hubo un error al eliminar la receta 😥' };
  }
};

export const addRecipe = async ({ state }, recipe) => {
  try {
    const recipePromise = await fetchWithToken(
      `/recetas`,
      recipe,
      'POST',
      true
    );

    const { status, message } = await recipePromise.json();

    const success = status === 'success';
    if (success) {
      return { status: success, msg: message };
    } else {
      return {
        status: success,
        msg: message,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      status: false,
      msg: 'Hubo un error al agregar la receta 😥',
    };
  }
};

export const updateRecipeMain = async ({ state }, recipe) => {
  const recipeID = state.recipeSelected.id;

  try {
    const recipePromise = await fetchWithToken(
      `/actualizarReceta/${recipeID}`,
      recipe,
      'POST',
      true
    );
    const { status, message } = await recipePromise.json();

    const success = status === 'success';
    if (success) {
      return { status: success, msg: message, recipeID };
    } else {
      return {
        status: success,
        msg: 'Hubo un error al actualizar la receta 😥',
        recipeID,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      status: false,
      msg: 'Hubo un error al actualizar la receta 😥',
      recipeID,
    };
  }
};

export const updateRecipeStatus = async ({ state }) => {
  const recipeID = state.recipeSelected.id;

  try {
    const recipePromise = await fetchWithToken(`/recetaRevisada/${recipeID}`);
    const { status, message } = await recipePromise.json();
    const success = status === 'success';

    if (success) {
      return {
        status: success,
        msg: message,
      };
    }

    return {
      status: success,
      recipeID,
      msg: 'Hubo un error al actualizar el estado de la receta 😥.',
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      recipeID,
      msg: 'Hubo un error al actualizar el estado de la receta 😥.',
    };
  }
};

export const addRecipePreparationStep = async ({ state, getters }, recipe) => {
  const recipeID = state.recipeSelected.id;
  const stepOrders = getters.getRecipeSelectedStepOrders;
  const orden = recipe.orden;

  if (stepOrders.includes(orden) && stepOrders.length > 0) {
    return {
      status: false,
      msg: 'El paso ingresado ya se encuentra registrado 🧐.',
    };
  } /* else if (stepOrders.length + 1 !== orden) {
    return {
      status: false,
      msg: 'El paso ingresado no es correlativo 🧐.',
    };
  } */

  try {
    const recipePromise = await fetchWithToken(
      `/agregarPasoReceta`,
      { ...recipe, receta_id: recipeID },
      'POST'
    );
    const { status, message } = await recipePromise.json();
    const succes = status === 'success';

    if (succes) {
      return {
        status: succes,
        msg: message,
        recipeID,
      };
    }
    return {
      status: succes,
      msg: 'Hubo un error al agregar el paso de preparación 😥.',
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      msg: 'Hubo un error al agregar el paso de preparación 😥.',
    };
  }
};

export const updateRecipePreparationStep = async (
  { state },
  { orden_id, titulo, orden, descripcion }
) => {
  const recipeID = state.recipeSelected.id;
  const orderMax = state.recipeSelected.preparationSteps.length;

  if (orden > orderMax) {
    return {
      status: false,
      msg: `La orden del paso de preparación debe ser max. o igual a ${orderMax} 😥.`,
    };
  }

  try {
    const recipePromise = await fetchWithToken(
      `/actualizarPasoReceta/${orden_id}`,
      { titulo, orden, descripcion, receta_id: recipeID },
      'PUT'
    );
    const { status, message } = await recipePromise.json();
    const success = status === 'success';
    if (success) {
      return {
        status: success,
        msg: message,
        recipeID,
      };
    }

    return {
      status: success,
      recipeID,
      msg: 'Hubo un error al actualizar el paso de preparación 😥.',
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      recipeID,
      msg: 'Hubo un error al actualizar el paso de preparación 😥.',
    };
  }
};

export const deleteRecipePreparationStep = async ({ state }, orderId) => {
  const recipeID = state.recipeSelected.id;

  try {
    const recipePromise = await fetchWithToken(
      `/eliminarPasoReceta/${orderId}`,
      {},
      'DELETE'
    );
    const { status, message } = await recipePromise.json();
    const success = status === 'success';
    if (success) {
      return {
        status: success,
        msg: message,
        recipeID,
      };
    }

    return {
      status: success,
      recipeID,
      msg: 'Hubo un error al eliminar el paso de preparación 😥.',
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      recipeID,
      msg: 'Hubo un error al eliminar el paso de preparación 😥.',
    };
  }
};

export const updateRecipeUnitsAdd = async (_, ingrediente_id) => {
  try {
    const recipePromise = await fetchWithToken(
      `/mostrarUnidadesMedida`,
      { ingrediente_id },
      'POST'
    );
    const units = await recipePromise.json();
    if (units.length > 0) {
      return { status: true, units };
    } else {
      return {
        status: false,
        msg: `No se encontraron las unidades del ingrediente 😥`,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      status: false,
      msg: `Hubo un error al obtener las unidades del ingrediente 😥`,
    };
  }
};

export const addRecipeIngredient = async ({ state }, { amount, unit, id }) => {
  const receta_id = state.recipeSelected.id;

  try {
    const recipePromise = await fetchWithToken(
      `/registrarIngredienteReceta`,
      { receta_id, cantidad: amount, unidadMedida: unit, ingrediente_id: id },
      'POST'
    );

    const { status, message } = await recipePromise.json();
    const success = status === 'success';
    if (success) {
      return {
        status: success,
        recipeID: receta_id,
        msg: message,
      };
    }
    return {
      status: success,
      msg: 'Hubo un error al agregar el ingrediente 😥.',
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      msg: 'Hubo un error al agregar el ingrediente 😥.',
    };
  }
};

export const updateRecipeUnitsEdit = async ({ commit }, ingrediente_id) => {
  try {
    const recipePromise = await fetchWithToken(
      `/mostrarUnidadesMedida`,
      { ingrediente_id },
      'POST'
    );
    const units = await recipePromise.json();
    if (units.length > 0) {
      commit('editRecipeIngredient', {
        idIngredient: ingrediente_id,
        unitList: units,
      });
      return { status: true, units };
    } else {
      return {
        status: false,
        msg: `No se encontraron las unidades del ingrediente 😥`,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      status: false,
      msg: `Hubo un error al obtener las unidades del ingrediente 😥`,
    };
  }
};

export const updateRecipeIngredient = async ({ state }, { amount, unit }) => {
  const {
    idIngredient: ingrediente_id,
    idRecipeIngredient,
  } = state.recipeSelected.ingredientEdit;

  const receta_id = state.recipeSelected.id;
  const cantidad = amount;
  const unidadMedida = unit;

  try {
    const recipePromise = await fetchWithToken(
      `/actualizarIngredienteReceta/${idRecipeIngredient}`,
      { ingrediente_id, receta_id, cantidad, unidadMedida },
      'PUT'
    );

    const { status, message } = await recipePromise.json();
    const success = status === 'success';

    if (success) {
      return {
        status: success,
        recipeID: receta_id,
        msg: message,
      };
    }
    return {
      status: success,
      recipeID: receta_id,
      msg: 'Hubo un error al actualizar el ingrediente 😥.',
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      recipeID: receta_id,
      msg: 'Hubo un error al actualizar el ingrediente 😥.',
    };
  }
};

export const deleteRecipeIngredient = async ({ state }, idRecipeIngredient) => {
  const recipeID = state.recipeSelected.id;
  try {
    const recipePromise = await fetchWithToken(
      `/deleteListado/${idRecipeIngredient}`,
      {},
      'DELETE'
    );

    const { status, message } = await recipePromise.json();
    const success = status === 'success';
    if (success) {
      return {
        status: success,
        recipeID,
        msg: message,
      };
    }
    return {
      status: success,
      recipeID,
      msg: 'Hubo un error al eliminar el ingrediente 😥.',
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      recipeID,
      msg: 'Hubo un error al eliminar el ingrediente 😥.',
    };
  }
};

export const registerCategory = async ({ state }, categoriaReceta_id) => {
  const receta_id = state.recipeSelected.id;
  try {
    const recipePromise = await fetchWithToken(
      `/registrarCategoriaReceta`,
      { receta_id, categoriaReceta_id },
      'POST'
    );
    const { status, message } = await recipePromise.json();
    const success = status === 'success';
    if (success) {
      return {
        status: success,
        recipeID: receta_id,
        msg: message,
      };
    }
    return {
      status: success,
      msg: 'Hubo un error seleccionar categoría 😥.',
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      msg: 'Hubo un error seleccionar categoría 😥.',
    };
  }
};
