const skeletons = [1, 2, 3, 4, 5, 6, 7, 8];

export default () => ({
  recipeSkeletons: skeletons,
  recipeList: {
    data: [],
    messageError: '',
    isLoading: false,
  },
  recipeSelected: {
    messageError: '',
    isLoading: false,
    data: {
      id: '',
      nombre: '',
      detalles: '',
      imagen: '',
      imagen2: '',
      imagen3: '',
      autor: '',
      porciones: '',
      tiempo: '',
      revisado: '',
    },
    id: '',
    preparationSteps: [],
    ingredientList: [],
    ingredientEdit: {
      idIngredient: null,
      idRecipeIngredient: null,
      unitList: [],
    },
    categories: {
      metaCategories: [],
      data: {},
    },
  },
});
