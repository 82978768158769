import { createStore } from 'vuex';
import auth from '@/modules/auth/store';
import cmsIngredients from '@/modules/cms/store/ingredients';
import cmsRecipes from '@/modules/cms/store/recipes';
import cmsProducts from '@/modules/cms/store/products';

export default createStore({
  modules: {
    auth,
    cmsIngredients,
    cmsRecipes,
    cmsProducts,
  },
});
