export const getRecipesSkeletons = (state) => {
  return state.recipeSkeletons;
};

export const getRecipesList = (state) => {
  return state.recipeList;
};

export const isRecipeNew = (state) => {
  const recipeSelected = state.recipeSelected.data;
  const values = Object.values(recipeSelected);
  return values.filter((value) => value !== '').length === 0;
};

export const getRecipesByTerm = (state) => (term = '') => {
  if (term.length === 0) return state.recipeList.data;

  return state.recipeList.data.filter(({ nombre }) =>
    nombre.toLowerCase().includes(term.trim().toLowerCase())
  );
};

export const getRecipeMain = (state) => {
  return state.recipeSelected.data;
};

export const getRecipeSelected = (state) => {
  return state.recipeSelected;
};

export const getRecipeSelectedState = (state) => {
  return state.recipeSelected.data.revisado;
};

export const getRecipeSelectedSteps = (state) => {
  return state.recipeSelected.preparationSteps;
};

export const getRecipeSelectedStepByOrder = (state) => (order) => {
  let currentOrder;
  if (order !== '') currentOrder = parseInt(order);
  else currentOrder = state.recipeSelected.preparationSteps[0].orden;

  return state.recipeSelected.preparationSteps.filter(
    (step) => step.orden === currentOrder
  );
};

export const getRecipeSelectedStepOrders = (state) => {
  return [
    ...new Set(state.recipeSelected.preparationSteps.map((step) => step.orden)),
  ];
};

export const getRecipeIngredients = (state) => {
  return state.recipeSelected.ingredientList;
};

export const getRecipeIngredientEdit = (state) => {
  return state.recipeSelected.ingredientEdit;
};

export const getRecipeSelectedCategories = (state) => {
  return state.recipeSelected.categories;
};

/* UI */
export const showFabRecipe = (state) => {
  const isLoading = state.recipeSelected.isLoading;
  const recipeListLength = state.recipeList.data.length;
  const recipeSelected = state.recipeSelected.data;

  if (
    !isLoading &&
    recipeListLength > 0 &&
    Object.values(recipeSelected).filter((value) => value !== '').length === 0
  )
    return true;
  else return false;
};
