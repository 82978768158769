import { fetchNotToken } from '../../../helpers/fetch';

/* export const myAction = async({commit})=> {} */

export const signInUser = async ({ commit }, user) => {
  try {
    const resp = await fetchNotToken('/login', { ...user }, 'POST');
    const respJSON = await resp.json();

    if (respJSON.access_token) {
      commit('loginUser', { token: respJSON.access_token });
      return { status: true, message: 'Acceso autorizado' };
    } else if (respJSON.error) {
      return { status: false, message: 'Usuario no registrado' };
    }
  } catch (error) {
    console.error(error);
    return { status: false, message: 'Por favor vuelva a intentarlo' };
  }
};

export const checkAuthentication = async ({ commit }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    commit('logout');
    return { status: false };
  }
  return { status: true };
};
