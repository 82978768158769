const skeletons = [1, 2, 3, 4, 5, 6, 7, 8];

export default () => ({
  productSkeletons: skeletons,
  productList: {
    data: [],
    error: false,
    messageError: '',
    isLoading: false,
  },
  productSelected: {},
});
